import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    ComposedChart,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    Bar,
    ResponsiveContainer,
    CartesianGrid,
    ReferenceLine
} from 'recharts';

class CumulatedBlockStartsChart extends React.PureComponent {

    constructor(props) {

        super(props);

        const currentYear = new Date().getFullYear();

        this.state = {
            data: [],
            loading: false,
            year: currentYear,
            years: this.props.years,
        };

    }

    async fetchData(year) {
        const result = await axios.get(
            `/administration/charts/cumulated_block_starts?year=${year}`, {
                headers: {'Accept': 'application/json'}
            });

        if(result.status !== 200) {
            return;
        }
        this.setState({ data: result.data });
    }

    componentDidMount() {
        this.fetchData(this.state.year);
    }

    handleYearChange = (event) => {
        const selectedYear = parseInt(event.target.value, 10);
        this.setState({ year: selectedYear }, () => {
            this.fetchData(selectedYear);
        });
    };

    yearTarget = () => {
        const yearValues = {
            2026: 3000,
            2025: 3000,
            2024: 2250,
            2023: 2250,
            2022: 2250,
            2021: 1120,
            2020: 1050
        };
        return yearValues[this.state.year] || 0
    };

    dateFormatter = (date)  => {
        return new Date(date).toLocaleString(document.documentElement.lang, {year: 'numeric', month: '2-digit', day: '2-digit'});
    }

    render() {

        return (
            <div className='card'>
                <div className='card-heading border bottom d-flex justify-content-between align-items-center'>
                    <h4 className='card-title'>{this.props.title}</h4>
                    <div className='input-group w-auto'>
                        <div className='input-group-prepend'>
                            <span className='input-group-text'>Jahr</span>
                        </div>
                        <select className='form-control' value={this.state.year} onChange={this.handleYearChange}>
                            {this.state.years.map(year => (
                                    <option key={year} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='card-body'>
                    <ResponsiveContainer height={350}>
                        <ComposedChart data={this.state.data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey='date' height={80} tick={{angle: -45, textAnchor: 'end'}} interval={0} tickFormatter={this.dateFormatter} />
                            <YAxis domain={[0, this.yearTarget()]}/>
                            <Tooltip labelFormatter={this.dateFormatter}/>
                            <Bar dataKey='cumulated_participation_count' name='Blockteilnahmen kumuliert' stackId='participants' fill='#888da8' />
                            <Bar dataKey='existing_participation_count' name='Blockteilnahmen laufender Bildungsmaßnahmen' stackId='participants' fill='#5664A4' />
                            <Bar dataKey='new_participation_count' name='Blockteilnahmen neuer Bildungsmaßnahmen' stackId='participants' fill='#51AE2F' />
                            <Line name='Zielvorgabe' type='monotone' dataKey='target' stroke='#ff8c00' strokeWidth={2} dot={false} activeDot={{ r: 5 }} />
                            <ReferenceLine y={this.yearTarget()} label={{position: 'bottom',  value: 'Jahresziel'}} ifOverflow='extendDomain' stroke='#ff8c00' strokeWidth={2} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );

    }

}

CumulatedBlockStartsChart.propTypes = {
    resourcePath: PropTypes.string,
    title: PropTypes.string.isRequired,
    years: PropTypes.array
};

export default CumulatedBlockStartsChart;