import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { ComposedChart, XAxis, YAxis, Tooltip, Line, ResponsiveContainer, CartesianGrid, Bar } from 'recharts';
import DateTimeInput from "./DateTimeInput";

class ParticipationParetoChart extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            data: [],
            keyMap: {},
            loading: false,
            startDate: new Date(new Date().getFullYear(), 0, 1), // 01.01 of the current year
            endDate: new Date(new Date().getFullYear(), 11, 31) // 31.12 of the current year
        };

    }

    async fetchData() {
        const { startDate, endDate } = this.state;
        const result = await axios.get(
            '/administration/charts/participations', {
                headers: {'Accept': 'application/json'},
                params: {
                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0]
                }
            });

        if(result.status !== 200) {
            return;
        }

        const keyMap = {};

        result.data.forEach(d => {
            keyMap[d.key] = d.name;
        });

        this.setState({
            data: result.data,
            keyMap: keyMap
        });

    }

    componentDidMount() {
        this.fetchData();
    }

    handleDateChange = (key, date) => {
        this.setState({ [key]: date }, () => {
            this.fetchData();
        });
    };

    formatter = (value, name, props) => {

        if(props.dataKey === 'percentage') {
            return [`${value}%`, name];
        } else {
            return [value, name];
        }

    }

    labelFormatter = (key) => {
        return `[${key}] ${this.state.keyMap[key]}`;
    }

    tickFormatter = (value) => {
        return `${value}%`;
    }

    render() {
        const { startDate, endDate } = this.state;

        return (
            <div className='card'>
                <div className='card-heading border bottom d-flex justify-content-between align-items-center'>
                    <h4 className='card-title'>{this.props.title}</h4>
                    <div className='float-right d-flex column-gap-1'>
                        <div className='input-group w-auto'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text'>Von</span>
                            </div>
                            <DateTimeInput
                                    date={startDate}
                                    className='form-control'
                                    saveLabel='Speichern'
                                    displayTime={false}
                                    selected={startDate}
                                    onChange={(date) => this.handleDateChange('startDate', date)}
                                    dateFormat='dd.MM.yyyy'
                            />
                        </div>

                        <div className='input-group w-auto'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text'>Bis</span>
                            </div>
                            <DateTimeInput
                                    date={endDate}
                                    minDate={startDate}
                                    className='form-control'
                                    saveLabel='Speichern'
                                    displayTime={false}
                                    selected={endDate}
                                    onChange={(date) => this.handleDateChange('endDate', date)}
                                    dateFormat="dd.MM.yyyy"
                            />
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <ResponsiveContainer height={350}>
                        <ComposedChart data={this.state.data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey='key' height={80} interval={0} tick={{angle: -45, textAnchor: 'end'}}/>
                            <YAxis yAxisId='left' dataKey='participation_count' domain={[0, 'dataMax']}/>
                            <YAxis yAxisId='right' tickFormatter={this.tickFormatter} dataKey='percentage' orientation='right' domain={[0, 100]}/>
                            <Tooltip formatter={this.formatter} labelFormatter={this.labelFormatter}/>
                            <Bar yAxisId='left' name='Teilnehmer' dataKey='participation_count' fill='#5664A4' />
                            <Line yAxisId='right' name='Kumulierter Anteil' type='monotone' dataKey='percentage' stroke='#ff8c00' strokeWidth={2} dot={false} activeDot={{ r: 5 }} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );

    }

}

ParticipationParetoChart.propTypes = {
    resourcePath: PropTypes.string,
    title: PropTypes.string.isRequired
};

export default ParticipationParetoChart;